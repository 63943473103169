<template>
  <div class="container-fluid px-0" v-if="!isLoading">
    <ModalWarning
      v-if="notChargeableProduct"
      :title="$t('products.notChargeableProductModal.title')"
      :reason="warningReason"
      :advice="$t('products.notChargeableProductModal.advice')"
      @close="onCloseModal"
    ></ModalWarning>
    <ModalMemo
      v-if="productMemo && getProductMemoInfo"
      :title="productMemo.name"
      :sub-title="productMemo.description"
      modal-size="extraLarge"
      :data-cy="`memo-modal-${productMemo.code}`"
      @close="onCloseProductMemo"
      @add-to-cart="onAddToCart(productMemo)"
    >
      <template #body>
        <div class="d-flex">
          <img class="col-7 pl-0" :src="productMemo.image" alt="image" width="450px" />
          <div class="col-5 p-0">
            <div v-if="getProductMemoInfo.hasOwnProperty('object')">
              <p class="memo-label px-2">{{ $t("memo.object") }}</p>
              <div
                v-for="(object, index) in getProductMemoInfo.object"
                :key="`${index}-${object}`"
                class="d-flex ml-2 mb-3"
              >
                <em :class="getObjectIcons(index)" class="mr-2"></em>
                <p class="m-0">{{ object }}</p>
              </div>
            </div>
            <div v-if="getProductMemoInfo.hasOwnProperty('coverage')">
              <p class="memo-label px-2">{{ $t("memo.coverage") }}</p>
              <div
                v-for="(coverage, index) in getProductMemoInfo.coverage"
                :key="`${index}-${coverage}`"
                class="d-flex align-items-center ml-2 mb-3"
              >
                <em :class="getCoverageIcons(index)" class="mr-2"></em>
                <span class="m-0">{{ coverage }}</span>
                <em class="icon-check-square ml-2 icon-coverage-right"></em>
              </div>
            </div>
            <div v-if="getProductMemoInfo.hasOwnProperty('price')">
              <div class="memo-label mb-2 d-flex justify-content-between">
                <p class="mb-0 px-2">{{ $t("memo.price") }}</p>
                <p class="memo-month mb-0">{{ $t("memo.month", { value: "12" }) }}</p>
              </div>
              <div class="d-flex ml-2">
                <em class="icon-coin-euro mr-2"></em>
                <div class="font-weight-bold">
                  {{ getProductMemoInfo.price.price_min }} {{ $t("memo.to") }}
                  {{ getProductMemoInfo.price.price_max }}
                  <span>{{ getCurrencySymbol }}</span>
                </div>
              </div>
            </div>
            <div v-if="getProductMemoInfo.hasOwnProperty('additional_coverage')">
              <p class="memo-label px-2">{{ $t("memo.additional_coverage") }}</p>
              <div
                v-for="(coverage, index) in getProductMemoInfo.additional_coverage"
                :key="`${index}-${coverage}`"
                class="d-flex align-items-center ml-2 mb-3"
              >
                <em :class="getAdditionalCoverageIcons(index)" class="mr-2"></em>
                <span class="m-0">{{ coverage }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="getProductMemoInfo.hasOwnProperty('coverage_example')" class="mt-3">
          <p class="memo-label px-2">{{ $t("memo.coverage_example") }}</p>
          <div class="d-flex justify-content-between">
            <div
              v-for="(coverageExample, index) in getProductMemoInfo.coverage_example"
              :key="`${index}-${coverageExample}`"
              class="d-flex flex-column align-items-center"
            >
              <p class="mb-0">{{ coverageExample.label }}</p>
              <div class="font-weight-bold">
                {{ coverageExample.price }}
                <span>{{ getCurrencySymbol }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalMemo>
    <div class="row products mx-0" data-cy="products-content">
      <div class="products-leftsidebar col-lg-3 pt-5" data-cy="left-sidebar">
        <tree-product-domains :domain="getDomain" />
      </div>
      <div class="products-mainsection col">
        <product-list
          v-if="!isLoading"
          @not-chargeable-product="onNotChargeableProductSelect($event)"
          @icon-eye="onIconEyeClick($event)"
        ></product-list>
      </div>
      <transition name="fade">
        <div
          class="products-rightsidebar col-lg-3 col-xl-2 p-0"
          v-if="sidebarBasketIsVisible"
        >
          <basket></basket>
        </div>
      </transition>
    </div>
  </div>
  <spinner :no-message="true" class="mt-5" v-else></spinner>
</template>

<script>
import TreeProductDomains from "@/components/products/TreeProductDomains.vue"
import ProductList from "@/components/products/ProductList.vue"
import Basket from "@/components/products/Basket"
import { mapGetters } from "vuex"
import ModalWarning from "@/components/modal/ModalWarning"
import ModalMemo from "@/components/modal/ModalMemo"
import local_product_memo from "@/conf/product_memo"
import product_memo_icons from "@/conf/product_memo_icons"
import CurrencyService from "@/services/business/CurrencyService"

export default {
  name: "Products",
  components: {
    Basket,
    TreeProductDomains,
    ProductList,
    ModalWarning,
    ModalMemo,
  },
  data() {
    return {
      notChargeableProduct: null,
      productMemo: null,
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters("gui", ["sidebarBasketIsVisible"]),
    ...mapGetters("reference", ["defaultProductDomain"]),
    ...mapGetters("product", ["isProductChargeable"]),
    ...mapGetters("config", ["getCurrentLang"]),
    ...mapGetters("basket", ["isItemInCurrentOffer", "basketIsEmpty"]),
    getDomain() {
      return this.$route.query.domain
        ? this.$route.query.domain
        : this.defaultProductDomain
    },
    getProductMemoInfo() {
      console.log("PRODUCT MEMO =>", this.productMemo)
      switch (this.getCurrentLang) {
        case "en":
          return local_product_memo["en"][this.productMemo?.code]
        case "es":
          return local_product_memo["es"][this.productMemo?.code]
        case "ko":
          return local_product_memo["ko"][this.productMemo?.code]
        default:
          return local_product_memo["fr"][this.productMemo?.code]
      }
    },
    getCurrencySymbol() {
      return CurrencyService.getCurrencySymbol()
    },
    warningReason() {
      return (
        this.$t("products.notChargeableProductModal.reason") +
        ' "' +
        `${this.notChargeableProduct.name}` +
        '"'
      )
    },
  },
  methods: {
    onNotChargeableProductSelect(product) {
      this.notChargeableProduct = product
    },
    onIconEyeClick(product) {
      this.productMemo = product
    },
    onCloseModal() {
      this.notChargeableProduct = null
    },
    onCloseProductMemo() {
      this.productMemo = null
    },
    async onAddToCart(product) {
      if (this.isProductChargeable(product.code)) {
        if (this.isItemInCurrentOffer(product.id)) {
          await this.$store.dispatch("basket/addNewOffer")
        }
        await this.$store.dispatch("basket/add", {
          ...product,
          ...{ _basketId: product.id },
        })
      } else {
        this.notChargeableProduct = product
      }
      this.productMemo = null
    },
    getObjectIcons(index) {
      return product_memo_icons[this.productMemo?.code][`object_${index + 1}`]
    },
    getCoverageIcons(index) {
      return product_memo_icons[this.productMemo?.code][`coverage_${index + 1}`]
    },
    getAdditionalCoverageIcons(index) {
      return product_memo_icons[this.productMemo?.code][
        `additional_coverage_${index + 1}`
      ]
    },
  },
  async created() {
    try {
      await this.$store.dispatch("product/initProducts")
      this.isLoading = false
    } catch (e) {
      console.error("failed: ", e)
    }
  },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.memo-label {
  background: #f0f0f0;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 3px;
  border-left: 3px solid $primary;

  .memo-month {
    text-transform: lowercase;
  }
}

em {
  font-size: 1.5rem;
}

.icon-coverage-right {
  font-size: 1.2rem;
}

.products {
  background-color: $base02;
  height: 100%;
  width: 100%;
  margin: 0;
}

.products-leftsidebar {
  background-color: #fff;
  box-shadow: 4px 0 4px -4px rgba(0, 0, 0, 0.25);
  min-height: 0;
  overflow-y: hidden;
}

.products-mainsection {
  overflow-y: hidden;
}

.products-rightsidebar {
  background-color: #fff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 8px rgba(0, 0, 0, 0.2);
  min-height: 0;
  min-width: 254px;
  margin: 25px 0 25px 0;
}
</style>
