<template>
  <div>
    <div class="image-form">
      <ImageUsers />
      <div class="form" :data-cy="dataCy">
        <FormRowInput
          :label-class="['col-sm-5', 'text-right']"
          :control-class="['col-sm-6']"
          :label="$t('users.label.firstname')"
          name="first_name"
          v-model="mutableUser.first_name"
          :errors="formErrors"
          required
        />
        <FormRowInput
          :label-class="['col-sm-5', 'text-right']"
          :control-class="['col-sm-6']"
          :label="$t('users.label.lastname')"
          name="last_name"
          v-model="mutableUser.last_name"
          :errors="formErrors"
          required
        />
        <FormRowInput
          :label-class="['col-sm-5', 'text-right']"
          :control-class="['col-sm-6']"
          :label="$t('users.label.email')"
          name="email"
          v-model="mutableUser.email"
          :errors="formErrors"
          @update:selected-option="onUpdateEmail()"
          required
        />
        <FormRowSelect
          :label-class="['col-sm-5', 'text-right']"
          :control-class="['col-sm-6']"
          :label="$t('users.label.role')"
          name="group_ids"
          :selected-option.sync="role"
          label-select-attr=""
          :select-options="toSelectOptions(rolesList)"
          :errors="formErrors"
          @update:selected-option="onUpdateRole()"
          required
        />
        <FormRowSelect
          :disabled="isDisabled('partner_id')"
          :label-class="['col-sm-5', 'text-right']"
          :control-class="['col-sm-6']"
          :label="$t('users.label.partner')"
          name="partner_id"
          data-cy="partner_id"
          :selected-option.sync="mutableUser.partner_id"
          label-select-attr=""
          :select-options="toSelectOptions(getPartners)"
          :errors="formErrors"
          :required="!isDisabled('partner_id')"
        />
        <FormRowSelect
          :disabled="isDisabled('company_id')"
          :label-class="['col-sm-5', 'text-right']"
          :control-class="['col-sm-6']"
          :label="$t('users.label.company')"
          name="company_id"
          data-cy="company_id"
          :selected-option.sync="mutableUser.company_id"
          label-select-attr=""
          :select-options="toSelectOptions(companiesList)"
          :errors="formErrors"
          :required="!isDisabled('partner_id')"
        />
        <div
          v-if="emailError"
          class="row invalid-feedback text-left"
          :data-cy="'username' + '-error'"
        >
          <i>{{ mutableUser.email }}</i>
          {{ emailError }}
        </div>
      </div>
    </div>
    <div class="form-actions">
      <router-link class="btn btn-blue-c-100 back-button" :to="{ name: 'usersList' }">
        {{ $t("users.back") }}
      </router-link>
      <SpinnerButton :is-loading="validationLoading" @click="submit" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { GUEST, BENEFICIARY, INTERNAL_ADMIN } from "@/services/business/GroupService"
import ImageUsers from "@/components/users/ImageUsers.vue"
import SpinnerButton from "@/components/button/SpinnerButton.vue"

export default {
  name: "UserForm",
  components: { SpinnerButton, ImageUsers },
  props: {
    user: { type: Object, required: true },
    dataCy: { type: String, default: "unset" },
    validationLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      role: null,
      emptyErrors: {},
      mutableUser: {},
      nullableFields: ["partner_id", "company_id"],
      emailError: null,
    }
  },
  computed: {
    ...mapGetters("users", [
      "getUsers",
      "getUsersStatus",
      "getUsersRole",
      "getSelectedUser",
      "rolesList",
      "errors",
      "companiesList",
    ]),
    ...mapGetters("partner", ["getPartners"]),
    formErrors() {
      return this.hasEmptyFields() ? this.emptyErrors : this.getErrors()
    },
    hasNoPartner() {
      const isGuest = parseInt(this.role) === GUEST
      if (isGuest) this.clearCompanyPartners()
      return !this.role || isGuest
    },
    unavailableFields() {
      return this.hasNoPartner ? this.nullableFields : []
    },
  },
  methods: {
    clearCompanyPartners() {
      this.emptyErrors = {}
    },
    hasEmptyFields() {
      return Object.keys(this.emptyErrors).length !== 0
    },
    toSelectOptions(list) {
      return Array.isArray(list)
        ? list.reduce((acc, value) => {
            acc[value.id] = value["name"]
            return acc
          }, {})
        : {}
    },
    async setInitialData() {
      await this.$store.dispatch("users/prepareUserForm")
      await this.$store.dispatch("partner/initPartners")
      this.mutableUser = this.user
      if (this.mutableUser && this.mutableUser.group) {
        const higherGroupId = this.mutableUser.group.id
        this.role = higherGroupId.toString()
      }
    },
    emptyFields() {
      const errors = {}
      for (const [key, value] of Object.entries(this.user)) {
        if (
          (this.areCompagnyAndPartnerDisabled() && this.nullableFields.includes(key)) ||
          value ||
          this.unavailableFields.includes(key)
        )
          continue

        errors[key] = this.$t("validation.required")
      }

      return errors
    },
    async submit() {
      this.$emit("update-validation-loading", true)

      if (this.role) {
        this.mutableUser.group_ids = [parseInt(this.role)]
      }

      this.emptyErrors = this.emptyFields()

      if (this.hasEmptyFields()) {
        this.$emit("update-validation-loading", false)
        return
      }
      this.$emit("submit", this.mutableUser)
    },
    areCompagnyAndPartnerDisabled() {
      return [GUEST, BENEFICIARY, INTERNAL_ADMIN].includes(parseInt(this.role))
    },
    isDisabled(inputName) {
      let isDisabled = this.areCompagnyAndPartnerDisabled()
      if (!isDisabled) {
        if (inputName === "partner_id") {
          isDisabled = this.hasNoPartner
        } else {
          isDisabled = !this.mutableUser.partner_id
        }
      }
      return isDisabled
    },
    onUpdateRole() {
      if (this.areCompagnyAndPartnerDisabled()) {
        this.mutableUser.partner_id = this.mutableUser.company_id = null
      }
    },
    onUpdateEmail() {
      this.emailError = null
    },
    getErrors() {
      if (this.errors?.username) this.errors["email"] = this.errors.username[0]
      return this.errors
    },
  },
  async beforeMount() {
    await this.setInitialData()
    this.$emit("update-is-form-ready")
  },
}
</script>

<style lang="scss" scoped>
.back-button:hover {
  background-color: lighten($bleuc100, 5);
  transition: background-color 200ms;
}

.invalid-feedback {
  display: block !important;
}

.form-actions {
  margin-top: 2rem;
}
</style>
