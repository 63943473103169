import i18n from "@/i18n"
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import Vue from "vue"
import store from "@/store/index"

// STATES (snake_case)
const state = {
  fields: [
    {
      field: "name",
      key: "a",
    },
    {
      field: "email",
      key: "b",
    },
    {
      field: "company",
      key: "c",
    },
    {
      field: "partner",
      key: "d",
    },
    {
      field: "status",
      key: "e",
      renderBodyCell: ({ row, column, rowIndex }, h) => {
        return <span data-cy={`user-${row.id}-status`}>{row.status}</span>
      },
    },
    {
      field: "role",
      key: "f",
    },
    {
      field: "edit",
      key: "g",
      renderBodyCell: ({ row, column, rowIndex }, h) => {
        return <i class="icon-pencil" data-cy={`edit-icon-${row.id}`}></i>
      },
    },
    {
      field: "block",
      key: "h",
      title: "",
      renderBodyCell: ({ row, column, rowIndex }, h) => {
        if (row.id !== store.getters["auth/getCurrentUser"].id) {
          return row.is_active ? (
            <i class="icon-prohibited" data-cy={`block-icon-${row.id}`}></i>
          ) : (
            <i class="icon-unlock" data-cy={`block-icon-${row.id}`}></i>
          )
        } else {
          return ""
        }
      },
    },
  ],
  fieldsToSort: ["name", "email", "company", "partner", "status", "role"],
  users: [],
  status: {
    0: "Inscrit",
    1: "Bloqué",
  },
  role: {},
  companies: {},
  user_form_errors: null,
  selected_user: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_USER(state, user) {
    const index = state.users.findIndex((u) => u.id === user.id)
    Vue.set(state.users, index, user)
  },
  SET_USERS(state, users) {
    state.users = users
  },
  SET_ROLE(state, roles) {
    state.role = roles
  },
  SET_COMPANIES(state, companies) {
    state.companies = companies
  },
  SET_USER_FORM_ERRORS(state, errors) {
    state.user_form_errors = errors
  },
  SET_SELECTED_USER(state, user) {
    state.selected_user = user
  },
}

// ACTIONS (camelCase)
const actions = {
  async toggleBlockUser({ commit }, user) {
    try {
      const updatedUser = await HttpService.put(
        UrlService.render("user", { id: user.id }),
        {
          is_active: !user.is_active,
        }
      )
      commit("SET_USER", updatedUser)
    } catch (e) {
      console.error("failed: ", e)
    }
  },
  async prepareUserForm({ commit }) {
    commit("SET_USER_FORM_ERRORS", null)
    let roles = await HttpService.get(UrlService.render("roles"))
    let companies = await HttpService.get(UrlService.render("companies"))
    commit("SET_ROLE", roles)
    commit("SET_COMPANIES", companies)
  },
  async addUser({ commit }, user) {
    commit("SET_USER_FORM_ERRORS", null)
    try {
      await HttpService.post(UrlService.render("createUserProfile"), user)
    } catch (e) {
      if (e.response.status === 422) {
        commit("SET_USER_FORM_ERRORS", e.data)
      } else {
        console.log("errors")
        throw e
      }
    }
  },
  async editUser({ commit }, user) {
    commit("SET_USER_FORM_ERRORS", null)
    try {
      await HttpService.put(
        UrlService.render("editUserProfile", {
          id: user.id,
        }),
        user
      )
    } catch (e) {
      if (e.response.status === 422) {
        commit("SET_USER_FORM_ERRORS", e.data)
      } else {
        throw e
      }
    }
  },
  async listUsers({ commit }, currentUser) {
    try {
      let users = await HttpService.get(
        UrlService.render("userList", { id: currentUser.id })
      )
      commit("SET_USERS", users)
    } catch (e) {
      console.error("failed: ", e)
    }
  },
  async retrieveUser({ commit }, userId) {
    try {
      let user = await HttpService.get(UrlService.render("user", { id: userId }))
      commit("SET_SELECTED_USER", user)
    } catch (e) {
      console.error("failed: ", e)
    }
  },
}

// GETTERS (camelCase)
const getters = {
  fieldsList(state) {
    return state.fields.map((field) => {
      if (state.fieldsToSort.includes(field.field)) {
        return {
          ...field,
          title: i18n.t("users.label." + field.field),
          sortBy: "",
        }
      } else {
        return {
          ...field,
        }
      }
    })
  },
  fieldsToSort: (state) => state.fieldsToSort,
  getUsers(state) {
    return state.users.map((user) => {
      return {
        ...user,
        name: user.first_name.concat(" ", user.last_name),
      }
    })
  },
  rolesList: (state) => state.role,
  companiesList: (state) => state.companies,
  errors: (state) => state.user_form_errors,
  getUsersStatus: (state) =>
    state.users.reduce((acc, user) => {
      acc[user.status] = user.status
      return acc
    }, {}),
  getUsersRole: (state) =>
    state.users.reduce((acc, user) => {
      acc[user.role] = user.role
      return acc
    }, {}),
  getSelectedUser: (state) => state.selected_user,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
