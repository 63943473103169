import AbstractService from "@/services/AbstractService"

class CurrencyService extends AbstractService {
  currencyCode = {
    kow: "₩",
    eur: "€",
  }

  getCurrencySymbol() {
    const currencySymbol = `${process.env.VUE_APP_CURRENCY_CODE}` || "eur"
    return this.currencyCode[currencySymbol]
  }
}

export default new CurrencyService()
