export default {
  fr: {
    extension_garantie_vn: {
      object: ["Pannes fortuites (électronique ou mécaniques)"],
      coverage: ["Pièces détachées", "Main d'oeuvre", "Fluide"],
      price: { price_min: 200, price_max: 500 },
    },
    garantie_vo: {
      object: ["Pannes fortuites (électronique ou mécaniques)"],
      coverage: ["Pièces détachées", "Main d'oeuvre", "Fluide"],
      price: { price_min: 150, price_max: 450 },
      coverage_example: [
        { label: "Système d'injection", price: "1 500" },
        { label: "Boite de vitesse automatique", price: "6 000" },
        { label: "Remplacement de l'alternateur", price: "600" },
      ],
    },
    extension_garantie_vn_elec: {
      object: ["Pannes fortuites (électronique ou mécaniques)"],
      coverage: ["Pièces détachées", "Main d'oeuvre", "Fluide"],
      price: { price_min: 150, price_max: 400 },
      coverage_example: [
        { label: "Pile à combustible", price: "4 500" },
        { label: "Réservoir d'hydrogène", price: "2 500" },
        { label: "Batterie", price: "2 000" },
      ],
    },
    rachat_franchise: {
      coverage: ["Pare-brise", "Clés", "Carrosserie", "Pneumatique"],
      price: { price_min: 50, price_max: 150 },
      coverage_example: [
        { label: "Frais de réparation", price: "900" },
        { label: "Remboursement de l'assurance automobile", price: "500" },
        { label: "Sans assurance complémentaire", price: "400" },
        { label: "Avec notre assurance complémentaire", price: "0" },
      ],
    },
    gap: {
      coverage: ["Dommage total", "Incendie", "Vol"],
      price: { price_min: 50, price_max: 100 },
      coverage_example: [
        { label: "Prix du véhicule", price: "35 000" },
        { label: "L'assurance automobile paie", price: "22 000" },
        { label: "Notre produit GAP paie", price: "12 000" },
      ],
    },
    gap_finance: {
      coverage: ["Dommage total", "Incendie", "Vol"],
      price: { price_min: 50, price_max: 100 },
      coverage_example: [
        { label: "Solde créditeur", price: "25 000" },
        { label: "L'assurance automobile paie", price: "21 000" },
        { label: "Notre produit GAP paie", price: "4 000" },
      ],
    },
    vitrerie_eclairage: {
      coverage: ["Pare-brise", "Vitres", "Vitre custode", "Rétroviseur"],
      price: { price_min: 0, price_max: 100 },
      coverage_example: [
        { label: "Coût de la main-d'oeuvre", price: "70" },
        { label: "Pièces détachées", price: "230" },
        { label: "Notre assurance vitrerie paie", price: "300" },
      ],
    },
    interior_care: {
      coverage: ["Taches", "Rayures", "Fissures"],
      price: { price_min: 50, price_max: 200 },
      coverage_example: [
        { label: "Coût de la main-d'oeuvre", price: "250" },
        { label: "Peinture, matériaux", price: "650" },
        { label: "Notre assurance Interior Care paie", price: "900" },
      ],
    },
    body_care: {
      coverage: ["Taches", "Rayures", "Fissures"],
      price: { price_min: 50, price_max: 200 },
      coverage_example: [
        { label: "Coût de la main-d'oeuvre", price: "250" },
        { label: "Peinture, matériaux", price: "650" },
        { label: "Notre assurance Body Care paie", price: "900" },
      ],
    },
    maintenance: {
      coverage: ["Pièces détachées", "Main d'oeuvre", "Fluide"],
      price: { price_min: 200, price_max: 600 },
    },
    garantie_perfo_batterie: {
      coverage: ["Pièces détachées", "Main d'oeuvre"],
      price: { price_min: 100, price_max: 200 },
      coverage_example: [
        { label: "Coût de la main-d'oeuvre", price: "800" },
        { label: "Batterie", price: "6 500" },
        { label: "Notre assurance Performance de la batterie paie", price: "7 300" },
      ],
    },
    lost_key: {
      coverage: [
        "Clés (y compris clés mains libres)",
        "Indemnité réédition papiers du véhicule",
      ],
    },
    maintenance_elec: {
      coverage: ["Pièces détachées", "Main d'oeuvre", "Fluide"],
      price: { price_min: 200, price_max: 600 },
    },
    assistance: {
      coverage: ["Dépannage", "Remorquage"],
      price: { price_min: 5, price_max: 20 },
      additional_coverage: ["Véhicule de remplacement", "De 15 à 50€ (pour 12 mois)"],
    },
  },
  en: {
    extension_garantie_vn: {
      object: ["Fortuitous breakdowns (electrical, electronical or mechanical)"],
      coverage: ["Spare Parts", "Labor Cost", "Fluids"],
      price: { price_min: 200, price_max: 500 },
    },
    garantie_vo: {
      object: ["Fortuitous breakdowns (electrical, electronical or mechanical)"],
      coverage: ["Spare Parts", "Labor Cost", "Fluids"],
      price: { price_min: 150, price_max: 450 },
      coverage_example: [
        { label: "Injection system", price: "1 500" },
        { label: "Automatic Gearbox", price: "6 000" },
        { label: "Alternator replacement", price: "600" },
      ],
    },
    extension_garantie_vn_elec: {
      object: ["Fortuitous breakdowns (electrical, electronical or mechanical)"],
      coverage: ["Spare Parts", "Labor Cost", "Fluids"],
      price: { price_min: 150, price_max: 400 },
      coverage_example: [
        { label: "Fuel Cell", price: "4 500" },
        { label: "Hydrogen Tank", price: "2 500" },
        { label: "Traction Battery", price: "2 000" },
      ],
    },
    rachat_franchise: {
      coverage: ["Windshield", "Keys", "Car Body", "Tires"],
      price: { price_min: 50, price_max: 150 },
      coverage_example: [
        { label: "Repair Cost", price: "900" },
        { label: "Primary Motor Insurance reimbursement", price: "500" },
        { label: "Without damage waiver", price: "400" },
        { label: "With our damage waiver insurance", price: "0" },
      ],
    },
    gap: {
      coverage: ["Total Loss - Damage", "Fire", "Theft"],
      price: { price_min: 50, price_max: 100 },
      coverage_example: [
        { label: "Vehicule original price", price: "35 000" },
        {
          label: "Primary Motor Insurance reimbursement (residual value)",
          price: "22 000",
        },
        { label: "Our GAP insurance complementary indemnity", price: "12 000" },
      ],
    },
    gap_finance: {
      coverage: ["Total Loss - Damage", "Fire", "Theft"],
      price: { price_min: 50, price_max: 100 },
      coverage_example: [
        { label: "Credit Balance", price: "25 000" },
        {
          label: "Primary Motor Insurance reimbursement (residual value)",
          price: "21 000",
        },
        { label: "Our GAP insurance complementary indemnity", price: "4 000" },
      ],
    },
    vitrerie_eclairage: {
      coverage: ["Windshield", "Glass", "Side mirror", "Lights"],
      price: { price_min: 0, price_max: 100 },
      coverage_example: [
        { label: "Labor Cost", price: "70" },
        { label: "Spare Parts", price: "230" },
        { label: "Our glass & lights insurance indemnity", price: "300" },
      ],
    },
    interior_care: {
      coverage: ["Stains", "Scratchs", "Cracks"],
      price: { price_min: 50, price_max: 200 },
      coverage_example: [
        { label: "Labor Cost", price: "250" },
        { label: "Paint, material", price: "650" },
        { label: "Our Interior Care insurance indemnity", price: "900" },
      ],
    },
    body_care: {
      coverage: ["Scratchs", "Dents", "Chips"],
      price: { price_min: 50, price_max: 200 },
      coverage_example: [
        { label: "Labor Cost", price: "250" },
        { label: "Paint, material", price: "650" },
        { label: "Our Body Care insurance indemnity", price: "900" },
      ],
    },
    maintenance: {
      coverage: ["Oil Change", "Preventive Maintenance", "Corrective Maintenance"],
      price: { price_min: 200, price_max: 600 },
    },
    garantie_perfo_batterie: {
      coverage: ["Spare Parts", "Labor Cost"],
      price: { price_min: 100, price_max: 200 },
      coverage_example: [
        { label: "Labor Cost", price: "800" },
        { label: "Battery replacement", price: "6 500" },
        { label: "Our Battery Performance insurance Reimburse", price: "7 300" },
      ],
    },
    lost_key: {
      coverage: [
        "Keys (including hands-free keys)",
        "Vehicle papers reissue allowance",
      ],
    },
    maintenance_elec: {
      coverage: ["Oil Change", "Preventive Maintenance", "Corrective Maintenance"],
      price: { price_min: 200, price_max: 600 },
    },
    assistance: {
      coverage: ["Breakdown", "Towing"],
      price: { price_min: 5, price_max: 20 },
      additional_coverage: ["Replacement vehicle", "15 to 50€ (for 12 months)"],
    },
  },
  es: {
    extension_garantie_vn: {
      object: ["Averías accidentales (electrónicas o mecánicas)"],
      coverage: ["Piezas sueltas", "Mano de obra", "Líquidos"],
      price: { price_min: 200, price_max: 500 },
    },
    garantie_vo: {
      object: ["Averías accidentales (electrónicas o mecánicas)"],
      coverage: ["Piezas sueltas", "Mano de obra", "Líquidos"],
      price: { price_min: 150, price_max: 450 },
      coverage_example: [
        { label: "Sistema de inyección", price: "1 500" },
        { label: "Caja de cambios automática", price: "6 000" },
        { label: "Reemplazo del alternador", price: "600" },
      ],
    },
    extension_garantie_vn_elec: {
      object: ["Averías accidentales (electrónicas o mecánicas)"],
      coverage: ["Piezas sueltas", "Mano de obra", "Líquidos"],
      price: { price_min: 150, price_max: 400 },
      coverage_example: [
        { label: "Pila de combustible", price: "4 500" },
        { label: "Tanque de hidrogeno", price: "2 500" },
        { label: "Batería", price: "2 000" },
      ],
    },
    rachat_franchise: {
      coverage: ["Parabrisas", "Llaves", "Carroceria", "Neumáticos"],
      price: { price_min: 50, price_max: 150 },
      coverage_example: [
        { label: "Costos de reparación", price: "900" },
        { label: "Reembolso de seguro de automóvil", price: "500" },
        { label: "Sin seguro adicional ", price: "400" },
        { label: "Con nuestro seguro complementario", price: "0" },
      ],
    },
    gap: {
      coverage: ["Daño total", "Incendio", "Robo"],
      price: { price_min: 50, price_max: 100 },
      coverage_example: [
        { label: "Precio del vehículo", price: "35 000" },
        {
          label: "El seguro auto paga",
          price: "22 000",
        },
        { label: "Nuestro producto GAP paga", price: "12 000" },
      ],
    },
    gap_finance: {
      coverage: ["Daño total", "Incendio", "Robo"],
      price: { price_min: 50, price_max: 100 },
      coverage_example: [
        { label: "Saldo acreedor", price: "25 000" },
        {
          label: "El seguro auto paga",
          price: "21 000",
        },
        { label: "Nuestro producto GAP paga", price: "4 000" },
      ],
    },
    vitrerie_eclairage: {
      coverage: ["Parabrisas", "Ventanas", "Cuarto de Ventana", "Espejo retrovisor"],
      price: { price_min: 0, price_max: 100 },
      coverage_example: [
        { label: "Mano de obra", price: "70" },
        { label: "Piezas sueltas", price: "230" },
        { label: "Nuestro seguro de acristalamiento paga", price: "300" },
      ],
    },
    interior_care: {
      coverage: ["Manchas", "Arañazos", "Grietas"],
      price: { price_min: 50, price_max: 200 },
      coverage_example: [
        { label: "Mano de obra", price: "250" },
        { label: "Pintura, material", price: "650" },
        { label: "Nuestro seguro Body Care paga", price: "900" },
      ],
    },
    body_care: {
      coverage: ["Manchas", "Arañazos", "Grietas"],
      price: { price_min: 50, price_max: 200 },
      coverage_example: [
        { label: "Mano de obra", price: "250" },
        { label: "Pintura, material", price: "650" },
        { label: "Nuestro seguro Body Care paga", price: "900" },
      ],
    },
    maintenance: {
      coverage: ["Piezas sueltas", "Mano de obra", "Líquidos"],
      price: { price_min: 200, price_max: 600 },
    },
    garantie_perfo_batterie: {
      coverage: ["Piezas sueltas", "Mano de obra"],
      price: { price_min: 100, price_max: 200 },
      coverage_example: [
        { label: "Mano de obra", price: "800" },
        { label: "Batería", price: "6 500" },
        { label: "Nuestro seguro de rendimiento de la batería paga", price: "7 300" },
      ],
    },
    lost_key: {
      coverage: [
        "Llaves (incluyendo manos libres)",
        "Subsidio por reemisión de papeles del vehículo",
      ],
    },
    maintenance_elec: {
      coverage: ["Piezas sueltas", "Mano de obr", "Líquidos"],
      price: { price_min: 200, price_max: 600 },
    },
    assistance: {
      coverage: ["Reparar", "Remolque"],
      price: { price_min: 5, price_max: 20 },
      additional_coverage: ["Vehículo de reemplazo", "De 15 a 50 € (sobre 12 meses"],
    },
  },
  ko: {
    extension_garantie_vn: {
      object: ["우발적 고장 (전기적, 전자적 또는 기계적)"],
      coverage: ["부품", "인건비", "오일류"],
      price: { price_min: 200, price_max: 500 },
    },
    garantie_vo: {
      object: ["우발적 고장 (전기적, 전자적 또는 기계적)"],
      coverage: ["부품", "인건비", "오일류"],
      price: { price_min: 150, price_max: 450 },
      coverage_example: [
        { label: "연료분사 시스템", price: "1 500" },
        { label: "오토기어박스", price: "6 000" },
        { label: "발전기 교환", price: "600" },
      ],
    },
    extension_garantie_vn_elec: {
      object: ["우발적 고장 (전기적, 전자적 또는 기계적)"],
      coverage: ["부품", "인건비", "오일류"],
      price: { price_min: 150, price_max: 400 },
      coverage_example: [
        { label: "연료셀", price: "4 500" },
        { label: "수소탱크", price: "2 500" },
        { label: "구동축전지", price: "2 000" },
      ],
    },
    rachat_franchise: {
      coverage: ["앞유리", "키", "차량 바디", "타이어"],
      price: { price_min: 50, price_max: 150 },
      coverage_example: [
        { label: "수리금액", price: "900" },
        { label: "기본 자동차 보험 환급", price: "500" },
        { label: "손상 면제 미포함", price: "400" },
        { label: "당사 손상 면제 보험 포함", price: "0" },
      ],
    },
    gap: {
      coverage: ["전체손실 – 파손", "화재", "도난"],
      price: { price_min: 50, price_max: 100 },
      coverage_example: [
        { label: "차량원가", price: "35 000" },
        {
          label: "기본 자동차 보험 환급 (잔존가치)",
          price: "22 000",
        },
        { label: "당사의 GAP 보험 보완 보상", price: "12 000" },
      ],
    },
    gap_finance: {
      coverage: ["전체손실 – 파손", "화재", "도난"],
      price: { price_min: 50, price_max: 100 },
      coverage_example: [
        { label: "신용잔액", price: "25 000" },
        {
          label: "기본 자동차 보험 환급 (잔존가치)",
          price: "21 000",
        },
        { label: "당사의 GAP 보험 보완 보상", price: "4 000" },
      ],
    },
    vitrerie_eclairage: {
      coverage: ["앞유리", "유리", "사이드 미러", "라이트"],
      price: { price_min: 0, price_max: 100 },
      coverage_example: [
        { label: "인건비", price: "70" },
        { label: "파트", price: "230" },
        { label: "유리 & 라이트 보호 보험 보상금", price: "300" },
      ],
    },
    interior_care: {
      coverage: ["얼룩", "긁힘", "균열"],
      price: { price_min: 50, price_max: 200 },
      coverage_example: [
        { label: "인건비", price: "250" },
        { label: "도색, 재료", price: "650" },
        { label: "바디케어 보험 보상금", price: "900" },
      ],
    },
    body_care: {
      coverage: ["긁힘", "눌림", "칩"],
      price: { price_min: 50, price_max: 200 },
      coverage_example: [
        { label: "인건비", price: "250" },
        { label: "도색, 재료", price: "650" },
        { label: "바디케어 보험 보상금", price: "900" },
      ],
    },
    maintenance: {
      coverage: ["오일교환", "예방적 유지관리", "보정적 유지관리"],
      price: { price_min: 200, price_max: 600 },
    },
    garantie_perfo_batterie: {
      coverage: ["부품", "인건비"],
      price: { price_min: 100, price_max: 200 },
      coverage_example: [
        { label: "인건비", price: "800" },
        { label: "배터리 교환", price: "6 500" },
        { label: "배터리 성능 보험 환급", price: "7 300" },
      ],
    },
    lost_key: {
      coverage: ["키(핸즈프리 키 포함)", "차량 서류 재발급 수당"],
    },
    maintenance_elec: {
      coverage: ["오일교환", "예방적 유지관리", "보정적 유지관리"],
      price: { price_min: 200, price_max: 600 },
    },
    assistance: {
      coverage: ["고장", "견인"],
      price: { price_min: 5, price_max: 20 },
      additional_coverage: ["차량교환", "15에서 50 (12개월간)"],
    },
  },
}
